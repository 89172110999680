



















































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import DrawingBoard from "@/components/DrawingBoard.vue";
function EndOfStep() {
  return import(/* webpackChunkName: "end-of-step" */ "./EndOfStep.vue");
}

import { getAPI } from "@/api/axios-base";
import rules from "@/components/account/rules";
import stepFielfdMixin from "./stepFielfdMixin";
import {
  notificationError,
  notifyError,
  notifyInfo,
} from "@/components/Notification";
import Errors from "./Errors.vue";
import { AxiosError } from "axios";
import { apiBaseUrl } from "@/enviorment";
import DocumentViewer from "./DocumentViewer.vue";
import TokenExpired from "./TokenExpired.vue";
import YesNotSelection from "@/components/YesNotSelection.vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "step3",
  mixins: [stepFielfdMixin],
  components: {
    DrawingBoard,
    Errors,
    EndOfStep,
    DocumentViewer,
    TokenExpired,
    YesNotSelection,
    MaDatePicker,
  },
  data() {
    return {
      formValid: false,
      stepSended: false,
      loading: false,
      rules,
      billTo: 0,
      priority: 0,
      anesthesia: 0,
      toBePerformedonAfterMenu: false,
      signature: new Blob(),
      showErrors: false,
      errorMessage: "",
      showDocuments: false,
      fields: {
        _12Lead: false, //
        anesthesiaG: false, //
        anesthesiaL: false, //
        bbMamogram: false, //
        bbUltrasound: false, //
        btDoctor: false, //
        btInsurance: false, //
        btPatient: false, //
        cbc: false, //
        chestXray: false, //
        cmp: false, //
        completeUri: false, //
        covid19: false, //
        dcOther: false, //
        dcOtherDescription: "", //
        // diagnosis: "", //
        freeT3: false, //
        freeT4: false, //
        hcgQualSerum: false, //
        hepatitisPanel: false, //
        hgbA1c: false, //
        hivAA: false, //
        labsOther: false, //
        labsOtherDescription: "", //
        niconiteTU: false, //
        // notes: "", //
        npiNo: "", //
        pcDoctorMedical: false, //
        pcDoctorOficeNotes: false, //
        pcDoctorPhysical: false, //
        preaACname: "", //
        procedure: "", //
        pRoutine: false, //
        pStat: false, //
        pTInr: false, //
        ptt: false, //
        specialist: "", //
        specialistMedical: false, //
        specialistOfficeNotes: false, //
        specialistPExam: false, //
        surgeonName: "", //
        toBePerformedonAfter: "", //
        tsh: false, //
        token: "",
      },
      currentDocuments: [],
      tokenExpired: false,
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    canSubmit(): boolean {
      const haveSignature = this.signature.size > 0;

      return haveSignature && this.formValid;
    },
    documents(): any[] {
      const token = this.$route.params.uuid;

      return [
        {
          title: "Pre-Surgery Order",
          url: `${apiBaseUrl}/patient/getPdfJonesRx/${token}`,
        },
      ];
    },
    documentsStep1(): any[] {
      const token = this.$route.params.uuid;

      return [
        {
          title: this.getpatientN + " Contract",
          url: `${apiBaseUrl}/patient/getPdfDorisContracts/${token}`,
        },
        {
          title: "Card contract",
          url: `${apiBaseUrl}/patient/getPdfCardContracts/${token}`,
        },
      ];
    },
    documentsStep2(): any[] {
      const token = this.$route.params.uuid;

      return [
        {
          title: "Wellcome package",
          url: `${apiBaseUrl}/patient/getWelcome_PackagePdf/${token}`,
        },
        {
          title: "Acknowledgement",
          url: `${apiBaseUrl}/patient/getAcknowledgementPdf/${token}`,
        },
        {
          title: "Medical information",
          url: `${apiBaseUrl}/patient/getPdfMedicalInformation/${token}`,
        },
        {
          title: "Video and photo autorization",
          url: `${apiBaseUrl}/patient/getPdfVideoPhoto/${token}`,
        },
        {
          title: this.getpatientN + " rights",
          url: `${apiBaseUrl}/patient/getPdfPatientRight/${token}`,
        },
      ];
    },
  },
  watch: {
    billTo(value: number): void {
      switch (value) {
        case 0:
          this.fields.btPatient = true;
          this.fields.btInsurance = false;
          this.fields.btDoctor = false;
          break;
        case 1:
          this.fields.btPatient = false;
          this.fields.btInsurance = true;
          this.fields.btDoctor = false;
          break;
        case 2:
          this.fields.btPatient = false;
          this.fields.btInsurance = false;
          this.fields.btDoctor = true;
          break;
      }
    },
    priority(value: number): void {
      switch (value) {
        case 0:
          this.fields.pStat = true;
          this.fields.pRoutine = false;
          break;
        case 1:
          this.fields.pStat = false;
          this.fields.pRoutine = true;
          break;
      }
    },
    anesthesia(value: number): void {
      switch (value) {
        case 0:
          this.fields.anesthesiaG = true;
          this.fields.anesthesiaL = false;
          break;
        case 1:
          this.fields.anesthesiaG = false;
          this.fields.anesthesiaL = true;
          break;
      }
    },
    toBePerformedonAfterMenu(val: boolean) {
      try {
        val &&
          setTimeout(
            () =>
              (this.$refs.toBePerformedonAfterDatepicker.activePicker = "YEAR")
          );
      } catch (error) {
        /*  */
      }
    },
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    async sendData() {
      const formData = new FormData();
      const { token } = this.fields;

      this.loading = true;
      try {
        await getAPI.post("/patient/signStep3", this.fields);
        // Send signature
        formData.append("firma", this.signature, `signature-${token}`);
        await getAPI({
          method: "post",
          url: `/patient/uploadPatienSign/${token}/STEP_3`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        notifyInfo("Your request has been sended for revision");
        this.stepSended = true;
        this.loading = false;
      } catch (error: any) {
        let mess = error.response.data.message;

        if (mess.includes("[")) {
          mess = mess.replace("[", "");
          mess = mess.replace("]", "");
        }
        notifyError(error.response.data, `An error occurred: ${mess}`);

        this.loading = false;
      }
    },
    saveToBePerformedonAfter(date: string): void {
      this.$refs.toBePerformedonAfterMenu.save(date);
    },
    showAllDocuments() {
      (this as any).currentDocuments = [];
      setTimeout(() => {
        (this as any).currentDocuments = [
          ...this.documentsStep1,
          ...this.documentsStep2,
          ...this.documents,
        ];
        this.showDocuments = true;
      }, 1000);
    },
    showDocumentsStep3() {
      (this as any).currentDocuments = this.documents;
      this.showDocuments = true;
    },
    checkToken() {
      getAPI("/patient/checkToken/" + this.$route.params.uuid)
        .then(() => {
          this.fields.token = this.$route.params.uuid;
          (this as any).$refs.form.validate();
        })
        .catch((error: AxiosError) => {
          if (error.response?.status == 404) {
            this.tokenExpired = true;
          }
        });
    },
  },
});
